import {
  Button,
  Checkbox,
  Chip,
  FileUpload,
  HSpacer,
  Input,
  MenuItem,
  Modal,
  Select,
  Text,
  TextAreaInput,
  VSpacer,
} from "@/components/DesignSystem";
import { ReactComponent as ProductIcon } from '@/assets/icons/product-icon.svg';
import { InnerContainer } from "@/components/shared/InnerContainer";
import { productDrawerConstant, viewProductConstant } from "@/constants/productConstant";
import {
  useActiveIngredients,
  useCategoryList,
  useCropList,
  useGetRetailerList,
  useManufacturerList,
  usePackageSizeList,
  usePracticeList,
  usePrimaryNutrient,
  useUoMList,
} from "@/hooks/useProductQuery";
import {
  ApiCategory,
  ApiCrop,
  ApiPackage,
  ApiPractice,
  ApiPrimaryNutrient,
  ApiRetailerList,
  ApiSubcategory,
  ApiUom,
} from "@api/interfaces";
import { Cancel } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Alert, Box, ListItemText, SelectChangeEvent, Stack } from "@mui/material";
import { intersection } from 'lodash';
import React, { useEffect, useMemo, useState } from "react";
import { ProductModalProps, StepperFieldDataType } from "./Interfaces";
import { ProductData } from './ProductList';
import StepperModal from "./StepperModal";

export interface ProductStepperData {
  categoryId: string;
  checked: boolean | undefined;
  id: string;
  isFeatured: boolean;
  image: string | null;
  ingredientNames: string[] | null;
  isRestrictedUse: boolean;
  keywords: string[];
  name: string;
  purchaseUom: string[];
  packageSize: string[];
  sellSheet: string;
  status: string | null;
  rank: number;
}

const CreateOrUpdateProductModal = ({
  data,
  handleSubmitData,
  isUpdating,
  isVisible,
  onClose,
  productField,
  setImageChange,
  setProductField,
}: ProductModalProps) => {
  const [errorMessage] = useState("");

  const [imageError, setImageError] = useState<string>("");
  const [imageName, setImageName] = useState<string>("");
  const { categoryList } = useCategoryList();
  const { primaryNutrientList } = usePrimaryNutrient();
  const { activeIngredientsDataList } = useActiveIngredients();
  const { manufacturerList } = useManufacturerList();
  const { cropsList } = useCropList();
  const { PracticeList } = usePracticeList();
  const { UoMList } = useUoMList();
  const { PackageSizeList } = usePackageSizeList();
  const [showAlterNativeModal, setShowAlterNativeModal] =
    useState<boolean>(false);
  const [addKeywords, setAddKeywords] = useState<string>("");
  const [showCompanionModal, setShowCompanionModal] = useState<boolean>(false);
  const [showCompanionSubmitted, setShowCompanionSubmitted] =
    useState<boolean>(false);
  const [showAlternativeSubmitted, setShowAlternativeSubmitted] =
    useState<boolean>(false);
  const [isLoading, setIsIsLoading] = useState(false);
  const [productImg, setProductImg] = useState("");
  const [shouldUpdateEditData, setShouldUpdateEditData] = useState(true);
  const [showPrimaryNutrients, setShowPrimaryNutrients]= useState(false);
  const [showSubCategoryA1, setShowSubCategoryA1]= useState(false);
  const [showActiveIngredients, setShowActiveIngredients]=useState(false);

  const { retailerList } = useGetRetailerList();

  useEffect(() => {
    if (productField) {
      if(!productField.activeIngredientsIds &&  !productField.keywords){
        const newData = { ...data, activeIngredientsIds: [], keywords: [] };
        setProductField(newData);
      }
    }
  }, [data, productField, setProductField]);

  const handleOnChange =
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
      if (e.target.name === "isRestrictedUse") {
        setProductField({
          ...productField,
          [e.target.name]: e.target.value === "Yes",
        });
      } else if (e.target.name === "isFeatured") {
        setProductField({
          ...productField,
          [e.target.name]: !!Number(e.target.value),
        });
      } else {
        setProductField({
          ...productField,
          [e.target.name]: e.target.value,
        });
      }
      setIsIsLoading(!isLoading);
    };

  const addCompanionProduct = (updatedStepperData: ProductStepperData[]) => {
    let getId = null;
    if(isUpdating){
      getId = updatedStepperData?.map(
        (_item: { id: string; rank: number; name: string }) => {

          return {
            rank: _item.rank,
            productDetails:{
              id: _item.id,
              name: _item.name,
            } };
        },
      );
    } else {
      getId = updatedStepperData?.map(
        (_item: { id: string; rank: number; name: string }) => {
          return {
            id: _item.id,
            rank: _item.rank,
            name: _item.name,
          };
        },
      );
    }
    setShowCompanionModal(false);
    if(getId.length > 0){
      setShowCompanionSubmitted(true);
    }else{

      setShowCompanionSubmitted(false);
    }
    setProductField({
      ...productField,
      companionProducts: [...getId],
    });
  };
  const addAlternativeProduct = (updatedStepperData: ProductStepperData[]) => {
    let getId = null;
    if(isUpdating){
      getId = updatedStepperData?.map(
        (_item: { id: string; rank: number; name: string }) => {
          return {
            rank: _item.rank,
            productDetails:{
              id: _item.id,
              name: _item.name,
            } };
        },
      );
    } else {
      getId = updatedStepperData?.map(
        (_item: { id: string; rank: number; name: string }) => {
          return {
            id: _item.id,
            rank: _item.rank,
            name: _item.name,
          };
        },
      );
    }
    setShowAlterNativeModal(false);
    if(getId.length > 0){
      setShowAlternativeSubmitted(true);
    }else{

      setShowAlternativeSubmitted(false);
    }
    setProductField({
      ...productField,
      alternativeProducts: [...getId],
    });
  };
  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageError("");
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const selectedFiles = e.target.files[0];
      if (selectedFiles.size > 1024 * 1024) {
        return setImageError("Image size should be less than 1MB.");
      } else {
        reader.onload = (e) => {
          const base64String = (selectedFiles.name.replace(/\s/g, '') +
            "+" +
            e.target?.result) as string;
          const imageName = e.target?.result as string;
          const img = new Image();
          img.src = imageName;
          img.onload = function () {
            const height = img.height;
            const width = img.width;
            if (height !== width) {
              return setImageError(
                "The image does not have an equal height and width ratio.",
              );
            } else {
              setProductField({
                ...productField,
                image: base64String,
              });
              setImageChange(true);
              setProductImg(e.target?.result as string);
            }
          };
        };
        reader.readAsDataURL(selectedFiles);
        setImageName(selectedFiles.name);
      }
    }
  };

  const isCropsRequired =
    !(categoryList?.some(obj => obj.id === productField?.categoryId &&
        obj.name?.toLowerCase() === "energy")
      || false);

  const setEnableButton = () => (
    productField.name === ''
    || !productField?.productRetailers?.length
    || !productField?.categoryId
    || !productField?.productSubcategories?.length
    || (isCropsRequired && !productField?.productCrops?.length)
    || !productField?.purchaseUom?.length
    || !productField?.packageSize?.length
    || imageError.length > 0
  );

  const handleAddKeywords = () => {
    if (!(/^\s*$/.test(addKeywords))) {
      setProductField({
        ...productField,
        keywords: [...productField.keywords, addKeywords],
      });
    }
    setAddKeywords("");
  };
  const handleRemoveImage = () => {
    setImageChange(true);
    setProductField({
      ...productField,
      image: "",
    });
    setImageError("");
  };

  useEffect(() => {
    if (isUpdating) {
      setShowAlternativeSubmitted(true);
      setShowCompanionSubmitted(true);
    }
  }, [isUpdating]);

  const updateStepperData = () => {
    setShowAlterNativeModal(true);
  };

  function createRetailerData (
    id:string,
    businessName:string,
  ) {
    return {
      id,
      businessName,
    };
  }

  function createCropData (
    id: string,
    name: string | null,
    parentName: string | null,
  ) {
    return {
      id,
      name,
      parentName,
    };
  }

  const formattedRetailerData = data?.productRetailers?.map((item) => {
    const { retailerId, retailerDetails } = item;
    const businessName = retailerDetails?.name ?? '';
    const id = retailerId;
    return createRetailerData(id, businessName);
  });

  const formatedCropData = data?.productCrops?.map((item) => {
    return createCropData(item.crops?.id ?? '', item.crops?.name ?? '', '');
  });

  const formatedPrimaryNutrientData = data?.primaryNutrients?.map(({ id, name }) => {
    return createCropData(id, name, '');
  });

  const seedOption = categoryList?.find(({ id }: {id: string}) =>
    id === productField.categoryId);

  const masterSubcategories = seedOption?.masterSubcategories?.filter(({ id }: {id: string }) =>
    productField.productSubcategories?.map(({ id }: {id: string}) => id)?.includes(id),
  ) ?? [];

  const subcategoriesA1 = masterSubcategories
    ?.reduce((prev: ApiSubcategory[], curr) =>
      [...prev, ...(curr.subcategories ?? [])], [])
    .map((subcategory) => {
      const parentCategory = masterSubcategories.find(
        (category:{ id: string }) => category.id === subcategory.parentId,
      );
      return {
        ...subcategory,
        parentName: parentCategory ? parentCategory.name : null,
      };
    });

  const subCategoriesA1List = subcategoriesA1?.map((item) => {
    return createCropData(item.id, item.name, item.parentName);
  }).filter(Boolean);

  const selectedProductNutrientsData = data?.primaryNutrients?.map(({ id, name }) => {
    return createCropData(id, name, '');
  }).filter(Boolean);

  const formattedSubCategoryData = data?.productSubcategories?.map((item) => {
    if (item.subcategories?.label === 1){
      return {
        id: item.subcategories.id,
        name: item.subcategories.name,
      };
    }
  }).filter(Boolean);

  const formattedSubCategoryA1Data = data?.productSubcategories?.map((item) => {
    if (item.subcategories?.label === 2){
      return item.subcategories.id;
    }
  }).filter(Boolean).join('');

  const formatedManufactureData = data?.manufacturer?.id;

  const formatedPracticesData = data?.productPractices?.map((item) => {
    return createCropData(item.practices?.id ?? '', item.practices?.name ?? '', '');
  });
  useEffect(() => {
    if(isUpdating){
      if (formattedRetailerData && shouldUpdateEditData) {
        setProductField((prevEditData: ProductData) => ({
          ...prevEditData,
          productRetailers: formattedRetailerData,
        }));
        setShouldUpdateEditData(false);
      }
      if (formatedCropData && shouldUpdateEditData) {
        setProductField((prevEditData: ProductData) => ({
          ...prevEditData,
          productCrops: formatedCropData,
        }));
        setShouldUpdateEditData(false);
      }

      if (formatedManufactureData && shouldUpdateEditData) {
        setProductField((prevEditData: ProductData) => ({
          ...prevEditData,
          manufacturerId: formatedManufactureData,
        }));
        setShouldUpdateEditData(false);
      }

      if (formattedSubCategoryData && shouldUpdateEditData) {
        setProductField((prevEditData: ProductData) => ({
          ...prevEditData,
          productSubcategories: formattedSubCategoryData,
        }));
        setShouldUpdateEditData(false);
      }

      if (formatedPracticesData && shouldUpdateEditData) {
        setProductField((prevEditData: ProductData) => ({
          ...prevEditData,
          productPractices: formatedPracticesData,
        }));
        setShouldUpdateEditData(false);
      }

      if (formattedSubCategoryA1Data && shouldUpdateEditData) {
        setProductField((prevEditData: ProductData) => ({
          ...prevEditData,
          subcategoryA1: formattedSubCategoryA1Data,
        }));
        setShouldUpdateEditData(false);
      }

      if (formatedPrimaryNutrientData && shouldUpdateEditData) {
        setProductField((prevEditData: ProductData) => ({
          ...prevEditData,
          primaryNutrients: formatedPrimaryNutrientData,
        }));
        setShouldUpdateEditData(false);
      }
    }
  }, [isUpdating,formattedRetailerData,
    formatedCropData,
    formatedManufactureData,
    shouldUpdateEditData,
    data,
    productField,
    formattedSubCategoryData,
    formatedPracticesData,
    setProductField,
    formattedSubCategoryA1Data,
    selectedProductNutrientsData,
    formatedPrimaryNutrientData,
  ]);
  const handleDeleteKeyWords = (index: number) => {
    const updatedKeywords = [...productField.keywords];
    updatedKeywords.splice(index, 1);
    setProductField({
      ...productField,
      keywords: updatedKeywords,
    });
  };
  const getCategoryName = categoryList?.find((item:ApiCategory) => {
    if(item.id === productField.categoryId){
      return item;
    }
  });

  const getMasterSubcategoryName =  useMemo(() => {
    return seedOption?.masterSubcategories?.filter((item)=>
      productField.productSubcategories?.some((sub: ApiSubcategory)=> sub?.id === item?.id))
      .map((name)=> name.name) ?? [];
  }, [productField.productSubcategories, seedOption?.masterSubcategories]);


  useEffect(()=>{
    if(getCategoryName?.name === 'Crop Nutrition'){
      if(getMasterSubcategoryName.length > 0){
        const hasSubcategoryNameKeyWord = !!intersection(
          getMasterSubcategoryName,
          ['Specialty', 'Liquid', 'Micronutrient', 'Dry'],
        ).length;
        if (hasSubcategoryNameKeyWord) {
          setShowPrimaryNutrients(true);
          setShowActiveIngredients(false);
          setShowSubCategoryA1(false);
          setProductField((prev:ProductData) => ({
            ...prev,
            activeIngredientsIds:[],
            subcategoryA1:'',
          }));
        }else{
          setShowPrimaryNutrients(false);
          setShowActiveIngredients(false);
          setShowSubCategoryA1(false);
          setProductField((prev:ProductData) => ({
            ...prev, primaryNutrients: [],
            activeIngredientsIds:[],
            subcategoryA1:'',
          }));
        }}
      setShowActiveIngredients(false);
      setShowSubCategoryA1(false);
      setProductField((prev:ProductData) => ({
        ...prev,
        activeIngredientsIds:[],
        subcategoryA1:'',
      }));
    } else if(getCategoryName?.name === 'Crop Protection'){
      if(getMasterSubcategoryName.length > 0) {
        const hasSubcategoryNameKeyWord = !!intersection(
          getMasterSubcategoryName,
          ['Herbicides', 'Insecticides', 'Growth Regulators', 'Fungicides', 'Adjuvants'],
        ).length;
        if (hasSubcategoryNameKeyWord) {
          setShowActiveIngredients(true);
          setShowPrimaryNutrients(false);
          setShowSubCategoryA1(false);
          setProductField((prev: ProductData) => ({
            ...prev,
            primaryNutrients: [],
            subcategoryA1: '',
          }));
        } else {
          setShowActiveIngredients(false);
          setShowPrimaryNutrients(false);
          setShowSubCategoryA1(false);
          setProductField((prev: ProductData) => ({
            ...prev,
            activeIngredientsIds: [],
            primaryNutrients: [],
            subcategoryA1: '',
          }));
        }}
      setShowPrimaryNutrients(false);
      setProductField((prev:ProductData) => ({
        ...prev,
        primaryNutrients: [],
        subcategoryA1:'',
      }));
    } else if(getCategoryName?.name === 'Seed'){
      if(getMasterSubcategoryName.length > 0){
        const hasSubcategoryNameKeyWord = !!intersection(
          getMasterSubcategoryName,
          ['Conventional', 'Non-GMO', 'Traited'],
        ).length;
        if (hasSubcategoryNameKeyWord) {
          setShowActiveIngredients(false);
          setShowPrimaryNutrients(false);
          setShowSubCategoryA1(true);
          setProductField((prev:ProductData) => ({
            ...prev,
            primaryNutrients: [],
            activeIngredientsIds:[],
          }));
        } else {
          setShowActiveIngredients(false);
          setShowPrimaryNutrients(false);
          setShowSubCategoryA1(false);
          setProductField((prev:ProductData) => ({
            ...prev,
            primaryNutrients: [],
            activeIngredientsIds:[],
            subcategoryA1:'',
          }));
        }}
      setShowPrimaryNutrients(false);
      setShowActiveIngredients(false);
      setProductField((prev:ProductData) => ({
        ...prev,
        primaryNutrients: [],
        activeIngredientsIds:[],
      }));
    }else{
      setShowPrimaryNutrients(false);
      setShowActiveIngredients(false);
      setShowSubCategoryA1(false);
      setProductField((prev:ProductData) => ({
        ...prev,
        primaryNutrients: [],
        activeIngredientsIds:[],
        subcategoryA1:'',
      }));
    }
  },[getCategoryName?.name,
    getMasterSubcategoryName,
    productField.productSubcategories,
    setProductField]);


  return (
    <Modal
      acceptButton={() =>
        isUpdating ? (
          <Button
            disabled={setEnableButton()}
            onClick={()=>{
              handleSubmitData();
              onClose();}}
            sx={{ width:'50%' }}
            testID="button-demo-enable"
            variant='contained'
          >
            {productDrawerConstant.save}
          </Button>
        ) : (
          <Button
            disabled={setEnableButton()}
            onClick={() => {
              handleSubmitData(),
              setShowAlternativeSubmitted(false),
              setShowCompanionSubmitted(false);
            }}
            sx={{ width:'50%' }}
            testID="button-demo-enable"
            variant='contained'
          >
            {productDrawerConstant.save}
          </Button>
        )
      }
      cancelButton={(props) => (
        <Button
          {...props}
          onClick={() => {
            onClose();
            setShowAlternativeSubmitted(false);
            setShowCompanionSubmitted(false);
          }}
          sx={{ width:'50%' }}
          testID="button-demo-enable"
          variant='outlined'
        >
          {productDrawerConstant.cancel}
        </Button>
      )}
      onClose={onClose}
      open={isVisible}
      testID='add-or-edit-product-modal'
      title={isUpdating ? productDrawerConstant.editProduct : productDrawerConstant.addProduct}
      width={560}
    >
      <Box>
        <InnerContainer>
          {!!errorMessage && (
            <>
              <Alert color="error" icon={false}>
                {errorMessage}
              </Alert>
              <VSpacer mobileSize="5" size="8" />
            </>
          )}
          <Stack spacing={3}>
            <Stack direction='row'>
              <ProductIcon fill='white' />
              <Text>Generic Product</Text>
            </Stack>
            <Input
              helperText='required*'
              label={viewProductConstant.productName}
              name="name"
              onChange={handleOnChange}
              testID="product-name"
              value={productField?.name}
            />
            <Select
              label={viewProductConstant.Manufacturer}
              name="manufacturerId"
              onChange={handleOnChange}
              onClear={() => setProductField({ ...productField, manufacturerId: '' })}
              testID="manufacturerId"
              value={productField?.manufacturerId
              }
            >
              {manufacturerList &&
                manufacturerList?.map((item:{id:string, name:string}) => {
                  return (
                    <MenuItem key={item.id} testID="org-none" value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
            <Select
              hideAsterisk={true}
              label={viewProductConstant.retailers}
              multiple
              name="productRetailers"
              onChange={(e:SelectChangeEvent<unknown>) => {
                e.target.value = retailerList?.filter(
                  ({ id }) => (e?.target?.value as string)?.includes(id),
                ) || [];
                handleOnChange(e);
              }}
              renderValue={(value:unknown) =>{

                const selected = Array.isArray(value) ? value : [];

                const data = retailerList?.filter(
                  ({ id }) => selected?.includes(id)) || [];

                return data?.map(
                  (retailer) =>
                    retailer.name,
                ).join(", ");
              }}
              required
              testID="productRetailers"
              value={productField.productRetailers?.map(({ id }: { id: string }) => id) || []}
            >
              {retailerList &&
                !!retailerList?.length &&
                retailerList.map((item: ApiRetailerList) => {
                  return (
                    <MenuItem
                      key={item.id}
                      sx={{ maxWidth: '320px' }}
                      testID={item.id}
                      value={item.id}>
                      <Checkbox
                        checked={
                          productField.productRetailers?.some((selectedItem:{id:string}) =>
                            selectedItem.id === item.id)
                        }
                        id={item.id}
                        testID={`${item.id}retailers`}
                      >
                        <ListItemText primary={item.name} sx={{ wordBreak: 'break-word' }} />
                      </Checkbox>
                    </MenuItem>
                  );
                })}
            </Select>

            <Select
              hideAsterisk={true}
              label={viewProductConstant.categoryFilters}
              name="categoryId"
              onChange={(e) => {
                handleOnChange(e);
                setProductField((prev: ApiCategory) => {
                  return ({
                    ...prev,
                    subcategoryA1: '',
                    productSubcategories: [],
                  });});
              }}
              required
              testID="categoryId"
              value={
                productField.categoryId
              }
            >
              {categoryList &&
                categoryList?.map((item:ApiCategory) => {
                  return (
                    <MenuItem
                      key={item?.id}
                      testID={item?.id}
                      value={item?.id}
                    >
                      {item?.name}
                    </MenuItem>
                  );
                })}
            </Select>
            <Select
              hideAsterisk={true}
              label={viewProductConstant.SubcategoryA}
              multiple
              name="productSubcategories"
              onChange={(e: SelectChangeEvent<unknown>) => {
                e.target.value = seedOption?.masterSubcategories
                  ?.filter(({ id }: { id: string }) => (e.target.value as string).includes(id));
                handleOnChange(e);
                setProductField((prev: string[]) => ({
                  ...prev,
                  subcategoryA1: '',
                }));
              }}
              renderValue={(value:unknown) =>{
                const selected = Array.isArray(value) ? value : [];
                const data = seedOption?.masterSubcategories
                  ?.filter(({ id }: {id: string}) => selected.includes(id));
                return data
                  ?.map((sub) => sub?.name)
                  .join(", ");
              }}
              required
              testID="productSubcategories"
              value={productField.productSubcategories?.map(({ id }: { id: string }) => id) || []}
            >
              {
                seedOption?.masterSubcategories?.sort(
                  (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
                ).map((item) => {
                  return (
                    <MenuItem key={item.id} testID={item.id} value={item.id}>
                      <Checkbox
                        checked={
                          productField.productSubcategories?.some((selectedItem:{id:string}) =>
                            selectedItem?.id === item.id)
                        }
                        id={item.id}
                        testID={`${item.id}subcategory`}
                      >
                        <ListItemText primary={item.name} />
                      </Checkbox>
                    </MenuItem>
                  );
                })}
            </Select>
            {showPrimaryNutrients && (
              <Select
                label={viewProductConstant.primaryNutrient}
                multiple
                name="primaryNutrients"
                onChange={(e: SelectChangeEvent<unknown>) => {
                  e.target.value = primaryNutrientList
                    ?.filter(({ id }: { id: string }) => (e.target.value as string).includes(id));
                  handleOnChange(e);
                }}
                renderValue={(value:unknown) =>{
                  const selected = Array.isArray(value) ? value : [];
                  const data = primaryNutrientList
                    ?.filter(({ id }: {id: string}) => selected.includes(id));
                  return data
                    ?.map(
                      (sub:ApiPrimaryNutrient) =>
                        sub?.name,
                    )
                    .join(", ");
                }}
                testID="productPrimaryNutrients"
                value={productField.primaryNutrients?.map(
                  ({ id }: { id: string }) => id) || []}
              >
                {primaryNutrientList && primaryNutrientList?.length > 0 &&
                  primaryNutrientList?.map((_item:ApiPrimaryNutrient) => {
                    return (
                      <MenuItem
                        key={_item.id}
                        testID={_item.id}
                        value={_item.id}
                      >
                        <Checkbox
                          checked={
                            productField.primaryNutrients?.some((selectedItem:
                            {id:string})=>selectedItem?.id === _item.id)
                          }
                          id={_item.id}
                          testID={`${_item.id}Nutrient`}
                        >
                          <ListItemText primary={_item.name} />
                        </Checkbox>
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
            {showActiveIngredients && (
              <Select
                label={viewProductConstant.activeIngredient}
                multiple
                name="activeIngredientsIds"
                onChange={(e: SelectChangeEvent<unknown>) => {
                  handleOnChange(e);
                }}
                renderValue={(value:unknown) =>{
                  const selected = Array.isArray(value) ? value : [];
                  const data = activeIngredientsDataList
                    ?.filter(({ id }: {id: string}) => selected.includes(id));
                  return data
                    ?.map(
                      (sub:{name:string}) =>
                        sub?.name,
                    )
                    .join(", ");
                }}
                testID="activeIngredientsIds"
                value={productField.activeIngredientsIds || []}
              >
                {activeIngredientsDataList &&
                  activeIngredientsDataList?.length > 0 &&
                  activeIngredientsDataList?.map((_item:{id:string, name:string}) => {
                    return (
                      <MenuItem
                        key={_item.id}
                        testID={_item.id}
                        value={_item.id}
                      >
                        <Checkbox
                          checked={
                            productField?.activeIngredientsIds &&
                            productField.activeIngredientsIds.length > 0 &&
                            productField.activeIngredientsIds.some((id: string) =>
                              id === _item.id)
                          }
                          testID={`${_item.id}Nutrient`}
                        >
                          <ListItemText primary={_item.name} />
                        </Checkbox>
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
            {showSubCategoryA1 && (
              <Select
                label={viewProductConstant.subcategoryA1}
                name="subcategoryA1"
                onChange={handleOnChange}
                testID="subcategoryA1"
                value={productField?.subcategoryA1}
              >
                {  subCategoriesA1List &&
                  subCategoriesA1List?.length > 0 &&
                  subCategoriesA1List?.map((_item) => {
                    return (
                      <MenuItem
                        key={_item?.id}
                        testID={_item?.id}
                        value={_item?.id}
                      >
                        {_item.parentName} - {_item?.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
            <Select
              hideAsterisk={true}
              label="Crop type"
              multiple
              name="productCrops"
              onChange={(e: SelectChangeEvent<unknown>) => {
                const data = cropsList
                  ?.filter(({ id }: {id: string}) => (e.target.value as string).includes(id));
                e.target.value = data;
                handleOnChange(e);
              }}
              renderValue={(value:unknown) =>{
                const selected = Array.isArray(value) ? value : [];
                const data = cropsList
                  ?.filter(({ id }: {id: string}) => selected.includes(id));
                return data
                  ?.map(
                    (crop: ApiCrop) =>
                      crop?.name,
                  )
                  .join(", ");
              }}
              required={isCropsRequired}
              testID="productCrops"
              value={productField?.productCrops?.map(({ id }: { id: string }) => id)|| []}
            >
              {cropsList &&
                cropsList?.length > 0 &&
                cropsList?.map((item: ApiCrop) => {
                  return (
                    <MenuItem key={item.id} testID={item.id} value={item.id}>
                      <Checkbox
                        checked={ isUpdating ?
                          productField?.productCrops?.some(
                            (selectedItem:{id:string}) => selectedItem.id === item.id,
                          ) : productField.productCrops?.some(
                            (selectedItem:{id:string}) => selectedItem.id === item.id,
                          )}
                        id={item.id}
                        testID={`${item.id}crop`}
                      >
                        <ListItemText primary={item.name} />
                      </Checkbox>
                    </MenuItem>
                  );
                })}
            </Select>
            <Select
              label={viewProductConstant.practice}
              multiple
              name="productPractices"
              onChange={(e: SelectChangeEvent<unknown>) => {
                e.target.value = PracticeList?.filter(
                  ({ id }: { id: string }) => (e.target.value as string).includes(id),
                );
                handleOnChange(e);
              }}
              renderValue={(value:unknown) =>{
                const selected = Array.isArray(value) ? value : [];
                const data = PracticeList?.filter(
                  ({ id }: {id: string}) => selected.includes(id),
                );
                return data?.map((practices: ApiPractice) => practices?.name).join(", ");
              }}
              testID="productPractices"
              value={productField.productPractices?.map(({ id }: { id: string }) => id) || []}
            >

              {PracticeList &&
                PracticeList?.length > 0 &&
                PracticeList?.map((item: ApiPractice) => {
                  return (
                    <MenuItem key={item.id}
                              testID={item.id}
                              value={item.id}>
                      <Checkbox
                        checked={ isUpdating ?
                          productField?.productPractices?.some(
                            (selectedItem:{id:string})=> selectedItem.id === item.id,
                          ) : productField.productPractices?.some(
                            (selectedItem:{id:string}) => selectedItem.id === item.id,
                          )}
                        id={item.id}
                        testID={`${item.id}practices`}
                      >
                        <ListItemText primary={item.name} />
                      </Checkbox>
                    </MenuItem>
                  );
                })}
            </Select>
            <Select
              hideAsterisk={true}
              label={viewProductConstant.purchaseUoM}
              multiple
              name="purchaseUom"
              onChange={handleOnChange}
              renderValue={(selected) =>
                Array.isArray(selected) &&
                selected?.length > 0 &&
                selected.join(", ")
              }
              required
              testID="purchaseUom"
              value={productField.purchaseUom || []}
            >
              {UoMList &&
                UoMList.map((item: ApiUom) => {
                  return (
                    <MenuItem
                      key={item.name}
                      testID={item.name ?? ''}
                      value={item.name ?? ''}
                    >
                      <Checkbox
                        checked={
                          productField.purchaseUom?.indexOf(item?.name) > -1
                        }
                        id={item.id}
                        testID={`${item.name}uom`}
                      >
                        <ListItemText primary={item.name} />
                      </Checkbox>
                    </MenuItem>
                  );
                })}
            </Select>
            <Select
              hideAsterisk={true}
              label={viewProductConstant.packageSize}
              multiple
              name="packageSize"
              onChange={handleOnChange}
              renderValue={(selected) =>
                Array.isArray(selected) &&
                selected?.length > 0 &&
                selected.join(", ")
              }
              required
              testID="packageSize"
              value={productField.packageSize || []}
            >
              {PackageSizeList &&
                PackageSizeList.map((item: ApiPackage) => {
                  return (
                    <MenuItem
                      key={item.id}
                      testID={item.id}
                      value={item.name ?? ''}
                    >
                      <Checkbox
                        checked={
                          productField.packageSize?.indexOf(item.name) > -1
                        }
                        testID={`${item.id}packageSize`}
                      >
                        <ListItemText primary={item.name} />
                      </Checkbox>
                    </MenuItem>
                  );
                })}
            </Select>
            <Select
              hideAsterisk={true}
              label={viewProductConstant.restrictedUse}
              name="isRestrictedUse"
              onChange={handleOnChange}
              required
              testID="isRestrictedUse"
              value={productField.isRestrictedUse ? productDrawerConstant.yes
                : productDrawerConstant.no}
            >
              <MenuItem key={"yes"} testID={"yes"} value="Yes">
                {productDrawerConstant.yes}
              </MenuItem>
              <MenuItem key={"no"} testID={"no"} value="No">
                {productDrawerConstant.no}
              </MenuItem>
            </Select>
            <TextAreaInput
              id='product-drawer-sellsheet'
              label={viewProductConstant.sellSheet}
              maxLength={2000}
              maxRows={10}
              minRows={1}
              name="sellSheet"
              onChange={handleOnChange}
              testID="sellSheet"
              value={productField.sellSheet}
            />
            <Stack>
              <FileUpload
                handleOnChange={uploadImage}
                handleRemoveImage={handleRemoveImage}
                imageName={imageName}
                key={productField.image}
                name="image"
                productImg={productImg}
                value={productField.image}
              />
              <VSpacer size="3" />
              <Text color="error">{imageError}</Text>
            </Stack>

            <Stack>
              <Text category="body-small" testID={`Keywords`}>
                {viewProductConstant.Keywords}
              </Text>
              <VSpacer size="3" />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Input
                  id='add-keywords'
                  label={productDrawerConstant.addKeyword}
                  name="addNewManufacturer"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAddKeywords(e.target.value);
                  }}
                  testID="addManufacturer"
                  value={addKeywords}
                />
                <Button
                  onClick={handleAddKeywords}
                  testID="add-keywords-button"
                >
                  {productDrawerConstant.add}
                </Button>
              </Box>
            </Stack>
            {productField?.keywords?.length > 0 && (
              <Box>
                {productField.keywords.map((item: string, i: number) => {
                  return (
                    <>
                      <Chip
                        icon={<Cancel onClick={()=> handleDeleteKeyWords(i)}/> }
                        key={i}
                        label={item}
                        rounded={false}
                        testID={item}
                        variant="outlined"
                      />
                      <HSpacer size="3"/>
                    </>
                  );
                })}
              </Box>
            )}
            {!showCompanionSubmitted && (
              <Button
                onClick={() => setShowCompanionModal(true)}
                square
                startIcon={<AddIcon />}
                testID="show-companion-modal-button"
                variant="outlined"
              >
                {productDrawerConstant.companionProducts}
              </Button>
            )}
            {!showAlternativeSubmitted && (
              <Button
                onClick={() => setShowAlterNativeModal(true)}
                square
                startIcon={<AddIcon />}
                testID="show-alternative-modal-button"
                variant="outlined"
              >
                {productDrawerConstant.alternativeProducts}
              </Button>
            )}
            {showCompanionSubmitted && (
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "3px",
                  display: "flex",
                  padding: "20px",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Text fontSize="17px">{productDrawerConstant.companionProducts}</Text>
                  <VSpacer size="3" />

                  {productField?.companionProducts &&
                    productField?.companionProducts?.map((_item: StepperFieldDataType) => {
                      if (isUpdating) {
                        return (
                          <Text
                            fontSize="11px"
                            key={_item.productDetails.id}
                            multiline
                          >
                            {_item?.productDetails.name}
                          </Text>
                        );
                      } else {
                        return (
                          <Text fontSize="11px" key={_item.id} multiline>
                            {_item?.name}
                          </Text>
                        );
                      }
                    })}
                </Box>
                <Button
                  onClick={() => setShowCompanionModal(true)}
                  testID="button-demo-enabled-outline"
                  variant="outlined"
                >
                  {productDrawerConstant.update}
                </Button>
              </Box>
            )}
            {showAlternativeSubmitted && (
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "3px",
                  display: "flex",
                  padding: "20px",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Text fontSize="17px">{productDrawerConstant.alternativeProducts}</Text>
                  <VSpacer size="3" />
                  {productField &&
                    productField?.alternativeProducts.length > 0 &&
                    productField?.alternativeProducts.map((_item: StepperFieldDataType) => {
                      if (isUpdating) {
                        return (
                          <Text
                            fontSize="11px"
                            key={_item.productDetails.id}
                            multiline
                          >
                            {_item?.productDetails.name}
                          </Text>
                        );
                      } else {
                        return (
                          <Text fontSize="11px" key={_item.id} multiline>
                            {_item?.name}
                          </Text>
                        );
                      }
                    })}
                </Box>
                <Button
                  onClick={updateStepperData}
                  testID="button-demo-enabled-outline"
                  variant="outlined"
                >
                  {productDrawerConstant.update}
                </Button>
              </Box>
            )}
            <VSpacer size="3" />
          </Stack>
        </InnerContainer>
      </Box>
      {showCompanionModal && (
        <StepperModal
          close={() => setShowCompanionModal(false)}
          handleComplete={addCompanionProduct}
          isEdit={isUpdating}
          searchCountText={productDrawerConstant.companionSearchText}
          selectedStepperFieldData={productField.companionProducts}
          selectedStepperRankText=
            {`${productDrawerConstant.companionSelectedRankTex} ${productField?.name}`}
          stepperSelectedData={productField?.companionProducts}
          title={productDrawerConstant.companionProducts}
        />
      )}
      {showAlterNativeModal && (
        <StepperModal
          close={() => setShowAlterNativeModal(false)}
          handleComplete={addAlternativeProduct}
          isEdit={isUpdating}
          searchCountText={productDrawerConstant.alternativeSearchText}
          selectedStepperFieldData={productField.alternativeProducts}
          selectedStepperRankText={`${productDrawerConstant.alternativeSelectedRankTex} ${productField?.name}`}
          stepperSelectedData={productField?.alternativeProducts}
          title={productDrawerConstant.alternativeProducts}
        />
      )}
    </Modal>
  );
};

export default CreateOrUpdateProductModal;