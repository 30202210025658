import { Button, HSpacer, Text, Toolbar, VSpacer } from '@/components/DesignSystem';
import { Filter, FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';

import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useCategoryList, useGetRetailerList, useManufacturerList } from '@/hooks/useProductQuery';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ProductApi } from '@/utilities/api/ProductApi';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { getFarmerPromotions, getRetailerPromotions } from '@/utilities/Product';
import { RetailerEndpoint } from '@api/endpoints';
import { ApiCategory, ApiManufacturer, ApiRetailer } from '@api/interfaces';
import { Alert, Container, Divider, Pagination, Stack } from '@mui/material';
import React, { Fragment, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ProductListItem from './ProductListItem';
import ViewProductModal from './ViewProductModal';
import RetailerSelector from "@/components/shared/ListSelector/RetailerSelector";
import CreateOrUpdateProductModal from "@/pages/Admin/Product/CreateOrUpdateProductModal";
import AddIcon from "@mui/icons-material/Add";
import { useSearch } from "@/hooks/useSearch";

interface StepperProduct {
  rank: number;
  productDetails: {
    id: string;
    name: string;
  };
}
interface DataItem {
  id: string;
  name: string;
  description: string | null;
  icon: string | null;
  isFeatured: boolean;
  createdAt: string;
  updatedAt: string;
}
const initialProductDataField = {
  activeIngredientsIds: [],
  alternativeProducts: [],
  categoryId: '',
  companionProductsId: [],
  description: '',
  image: '',
  isFeatured: false,
  isRestrictedUse: false,
  keywords: [],
  manufacturer: '',
  name: '',
  packageSize: [],
  productCrops: [],
  productPractices: [],
  productPrimaryNutrients: [],
  productRetailers: [],
  productSubcategories: [],
  purchaseUom: [],
  retailersId: [],
  sellSheet: '',
  subcategoryA1: '',
};

export interface ProductData {
  activeIngredientsIds: string[],
  alternativeProducts: object[],
  categoryId: string,
  companionProducts?: object[],
  description?: string,
  id: string,
  image: string,
  isFeatured: boolean,
  isRestrictedUse: boolean,
  keywords: string[],
  manufacturer: string,
  name: string,
  packageSize: string[],
  productCrops: string[],
  productPractices: string[],
  productPrimaryNutrients?: string[],
  productRetailers: string[],
  productSubcategories: string[],
  purchaseUom: string[],
  retailersId: string[],
  sellSheet: string,
  subcategoryA1?: string,
}

const getAllData =
  (data: (
    ApiCategory[] | ApiManufacturer[] | RetailerEndpoint.RetailerList.Response | undefined)) => {
    const list: { id: string; label: string }[] = [];
    data && data?.map((item: { name: string | null ; id: string }) => {
      list.push({
        id: item.id,
        label: item.name ?? '',
      });
    });
    return list;
  };

const filterData = (
  categoryList?: ApiCategory[],
  partnerRetailerList?: RetailerEndpoint.RetailerList.Response,
  manufacturerList?: ApiManufacturer[],
  selectedRetailer?: ApiRetailer,
) => {
  const filters: Filter[] = [
    {
      id: 'sortDesc',
      label: 'Sort By',
      options: [
        { id: 'false', label: 'A-Z', default: false },
        { id: 'true', label: 'Z-A', default: false },
      ],
      selectionMethod: 'single-select',
    },
    {
      id: 'categoryId',
      label: 'Category',
      options: getAllData(categoryList),
      selectionMethod: 'single-select',
    },
    {
      id: 'manufacturerId',
      label: 'Manufacturer',
      options: getAllData(manufacturerList),
      selectionMethod: 'single-select',
    },
  ];
  if (!selectedRetailer) {
    filters.push({
      id: 'retailerId',
      label: 'Retailer',
      options: getAllData(partnerRetailerList),
      selectionMethod: 'single-select',
    });
  }
  return filters;
};

const ProductList = () => {
  const { internal: userIsInternal } = useAuthentication();
  const navigate = useNavigate();
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showEditProductModal, setShowEditProductModal] = useState<boolean>(false);
  const [showViewProductModal, setShowViewProductModal] = useState(false);
  const [errorMessage] = useState('');
  const [getCurrentProductId, setCurrentProductId] = useState<string>('');
  const [productField, setProductField] = useState<any>(
    initialProductDataField,
  );
  const { retailerList } = useGetRetailerList();
  const { retailerList: erpRetailerList } = useGetRetailerList({ hasErpProducts: true });
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer | undefined>();
  const { manufacturerList } = useManufacturerList({
    ...(selectedRetailer && { retailerId: selectedRetailer.id }),
  });
  const { categoryList } = useCategoryList({
    ...(selectedRetailer && { retailerId: selectedRetailer.id }),
  });
  const { openSnackbar } = useSnackbar();
  const [noProductRecord, setNoProductRecord] = useState('');
  const [imageChange, setImageChange] = useState(false);
  const [page, setPage] = useState(0);
  const { setSearch, debouncedSearch } = useSearch(3);
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  const filtersList: Record<string, string | boolean> = Array.from(
    filterSelections?.entries() ?? [],
  )
    .map(([filterId, selection]) => {
      return {
        filterId,
        value: Array.from(selection).join(', '),
      };
    })
    .reduce((obj, filter) => {
      obj[filter.filterId] = filter.value;
      return obj;
    }, {} as Record<string, string | boolean>);

  const query = {
    ...filtersList,
    hasExternalId: !!selectedRetailer,
    page,
    ...(selectedRetailer && { retailerId: [selectedRetailer.id] }),
    search: debouncedSearch,
  };

  const dataHandler = (data: DataItem[] | null | undefined) => {
    return data?.map((item) => item?.id ?? item) || [];
  };

  const stepperDataList = (data: StepperProduct[]) => {
    return data.map((item: StepperProduct) => {
      return {
        id: item.productDetails.id,
        rank: item.rank,
        name: item.productDetails.name,
      };
    });
  };

  const handleClearData = () => {
    setProductField(initialProductDataField);
    setShowViewProductModal(false);
  };

  if (!userIsInternal) {
    navigate(Routes.HOMEPAGE);
  }

  const updateProductList = useQueryClient();

  const onProductListItemClicked = (e: MouseEvent) => {
    e.stopPropagation();
    setShowViewProductModal(true);
  };

  const handleEdit = (id: string) => {
    setImageChange(false);
    setCurrentProductId(id);
    getoneDataList(id);
    setShowEditProductModal(true);
  };

  const { data: productListData } = useQuery(
    [QueryKeys.GET_PRODUCT, query],
    () => ProductApi.productListData(query),
  );

  const {
    mutate: getoneDataList,
    data,
    isLoading,
  } = useMutation(
    [QueryKeys.GET_PRODUCT],
    (id: string) => ProductApi.get(id, true),
    {
      onSuccess: (response) => {
        setProductField(response);
      },
    },
  );

  const productIds = productListData?.data.map(
    (product) => product.id,
  ).filter(Boolean) as string[] ?? [];

  const { data: promotions } = useQuery(
    [QueryKeys.GET_PROMOTIONS, productIds],
    async () => PromotionApi.list({
      isActive: true,
      productIds: productIds,
    }),
    {
      enabled: !!productIds.length,
    },
  );

  const handleAddData = () => {
    addProduct();
    setShowAddProductModal(false);
  };
  const handleEditData = () => {
    editProductList();
    setShowAddProductModal(false);
  };
  let updatedSubCategories = productField.productSubcategories;
  if (productField.subcategoryA1) {
    const updatedSubcategoryA1 = {
      id: productField.subcategoryA1,
    };
    updatedSubCategories = [
      ...productField.productSubcategories,
      updatedSubcategoryA1,
    ];
  }
  const { mutate: addProduct } = useMutation(
    () =>
      ProductApi.create({
        name: productField.name,
        categoryId: productField.categoryId,
        manufacturerId: productField.manufacturerId,
        image: productField.image,
        description: '',
        isFeatured: productField.isFeatured,
        purchaseUom: productField.purchaseUom,
        packageSize: productField.packageSize,
        sellSheet: productField.sellSheet,
        keywords: productField.keywords,
        isRestrictedUse: productField.isRestrictedUse,
        retailersIds: dataHandler(productField.productRetailers),
        companionProductsIds: productField.companionProducts,
        alternativeProductsIds: productField.alternativeProducts,
        cropIds: dataHandler(productField.productCrops),
        practicesIds: dataHandler(productField.productPractices),
        activeIngredientsIds: dataHandler(
          productField.activeIngredientsIds,
        ),
        subcategoryIds: dataHandler(updatedSubCategories),
        primaryNutrientIds: dataHandler(
          productField.primaryNutrients,
        ),
      }),
    {
      onError: (error: { code: string; message: string }) => {
        openSnackbar(error.message);
      },
      onSuccess: async () => {
        await updateProductList.invalidateQueries(QueryKeys.GET_PRODUCT);
        setProductField(initialProductDataField);
        openSnackbar('Product Added Successfully');
      },
    },
  );
  const { mutate: editProductList } = useMutation(
    () => {
      const productData = {
        name: productField.name,
        categoryId: productField.categoryId,
        description: '',
        image: productField.image,
        isFeatured: productField.isFeatured,
        purchaseUom: productField.purchaseUom,
        packageSize: productField.packageSize,
        sellSheet: productField.sellSheet,
        keywords: productField.keywords,
        isRestrictedUse: productField.isRestrictedUse,
        retailersIds: dataHandler(
          productField.productRetailers,
        ),
        manufacturerId: productField?.manufacturerId,
        companionProductsIds: stepperDataList(productField.companionProducts),
        alternativeProductsIds: stepperDataList(
          productField.alternativeProducts,
        ),
        cropIds: dataHandler(productField.productCrops),
        practicesIds: dataHandler(
          productField.productPractices,
        ),
        activeIngredientsIds: productField.activeIngredientsIds,
        subcategoryIds: dataHandler(updatedSubCategories),
        primaryNutrientIds: dataHandler(
          productField.primaryNutrients,
        ),
      };

      if (!imageChange) {
        delete productData.image;
      }

      return ProductApi.update(productData, getCurrentProductId);
    },
    {
      onError: (error: { code: string; message: string }) => {
        openSnackbar(error.message);
      },
      onSuccess: async () => {
        await updateProductList.invalidateQueries(QueryKeys.GET_PRODUCT);
        setShowAddProductModal(false);
        setProductField(initialProductDataField);
        openSnackbar('Product Updated Successfully');
      },
    },
  );
  const memoizedFilters = useMemo(
    () => filterData(categoryList, retailerList, manufacturerList, selectedRetailer),
    [categoryList, retailerList, manufacturerList, selectedRetailer],
  );

  useEffect(() => {
    if (productListData && productListData?.data?.length <= 0) {
      setNoProductRecord('No products yet');
    }
  }, [productListData]);

  const onAddProductButtonClicked = () => {
    setShowAddProductModal(true);
    handleClearData();
  };

  const onRetailerSelected = (retailer?: ApiRetailer) => {
    setSelectedRetailer(retailer);
    setFilterSelections(new Map());
  };

  return (
    <Container maxWidth='lg'>
      <VSpacer size="8" />
      <Stack alignItems="center" direction="row">
        <Text category="headline-medium">Products</Text>
        <HSpacer size='6' />
        {!!erpRetailerList?.length && (
          <RetailerSelector
            defaultMenuItem={{
              label: 'Generic',
              logo: 'product',
            }}
            onSelect={onRetailerSelected}
            retailers={erpRetailerList}
          />
        )}
      </Stack>
      <VSpacer size="6" />
      <Divider />
      <VSpacer size="6" />
      <Stack alignItems='center' direction='row' justifyContent='space-between'>
        <Toolbar
          filterSelections={filterSelections}
          filters={memoizedFilters}
          hideAllFilter={true}
          onChange={({ search, selections }) => {
            setSearch(search ?? '');
            setFilterSelections(selections);
          }}
          retainSelectionsOnFilterChange
          testID='searchAndFilter'
        />
        {!selectedRetailer && (
          <Button
            onClick={onAddProductButtonClicked}
            size='medium'
            startIcon={<AddIcon />}
            testID='manage-category-add-button'
          >
            Add Product
          </Button>
        )}
      </Stack>
      <VSpacer size='8' />
      {!!errorMessage && (
        <>
          <Alert color='error' icon={false}>
            {errorMessage}
          </Alert>
          <VSpacer mobileSize='5' size='8' />
        </>
      )}
      {productListData && productListData?.total > 0
        ? `${productListData?.total} Items`
        : '0 Item'}
      <VSpacer size='5' />
      {productListData?.data?.map((item) => (
        <Fragment key={item.id}>
          <ProductListItem
            editable={!selectedRetailer}
            farmerPromotions={getFarmerPromotions(promotions?.data ?? [], item)}
            getoneDataList={getoneDataList}
            handleEdit={handleEdit}
            onClick={onProductListItemClicked}
            productListData={{
              id: item.id,
              manufacturerName: item.manufacturer?.name,
              name: item.name ?? '',
            }}
            retailerPromotions={getRetailerPromotions(promotions?.data ?? [], item)}
            setIsEditProductField={setShowEditProductModal}
          />
          <VSpacer size='3' />
        </Fragment>
      ))}
      <VSpacer size='3' />
      {productListData && productListData.lastPage !== 0 && (
        <Stack alignItems='center'>
          <Pagination
            count={productListData.lastPage + 1}
            onChange={(event, page) => {
              setPage(page - 1);
            }}
            page={productListData.page + 1}
          />
        </Stack>
      )}
      <VSpacer size='14' />
      <Stack>
        {productListData?.data && productListData?.data.length <= 0 && (
          <Stack sx={{ textAlign: 'center' }}>
            {noProductRecord}
            <VSpacer size='14' />
          </Stack>
        )}
      </Stack>
      {showEditProductModal && productListData && productField && (
        <CreateOrUpdateProductModal
          data={data}
          handleSubmitData={handleEditData}
          isUpdating={true}
          isVisible={!isLoading}
          onClose={() => {
            setShowEditProductModal(false);
            handleClearData();
          }}
          onSave={() => {
            setShowEditProductModal(false);
            handleClearData();
          }}

          productField={productField}
          setImageChange={setImageChange}
          setProductField={setProductField}
        />
      )}

      {showAddProductModal && (
        <CreateOrUpdateProductModal
          data={data}
          handleSubmitData={handleAddData}
          isUpdating={false}
          isVisible
          onClose={() => {
            setShowAddProductModal(false);
            handleClearData();
          }}
          onSave={() => setShowAddProductModal(false)}
          productField={productField}
          setImageChange={setImageChange}
          setProductField={setProductField}
        />
      )}
      {showViewProductModal && (
        <ViewProductModal
          disableActions={!!selectedRetailer}
          isVisible
          onClose={handleClearData}
          onEdit={handleEdit}
          productId={data?.id}
        />
      )}
    </Container>
  );
};

export default ProductList;
